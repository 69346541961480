
<template>
  <div id="login">
    <!-- component -->
    <section class="min-h-screen flex items-stretch">
      <div class="bg-side-red lg:flex w-1/2 hidden">
        <div
          class="w-full h-full bg-cover opacity-25"
          style="background-image: url('static/images/download-bg.webp')"
        ></div>
      </div>

      <div
        class="bg-white lg:w-1/2 w-full flex items-center justify-center px-10"
      >
        <div class="w-full h-100" v-if="loginInView">
          <h1 class="my-6">XXX1a
            <img
              src="/images/passport-logo-red.webp" width="50%" height="50%"
              class="w-3/12 mx-auto opacity-50 mb-10"
            />
          </h1>

          <form class="mt-6 w-10/12 mx-auto" v-on:submit.prevent="login">
            <div>
              <label class="block text-gray-600">Username</label>
              <input
                type="text"
                placeholder="Enter your username"
                v-model="username"
                class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500 focus:bg-white focus:outline-none
                "
                autofocus
                autocomplete
                required
              />

              <div class="validation_error on_email" id="errorEmail">
                MUST ENTER A VALID EMAIL
              </div>
            </div>

            <div class="mt-4">
              <label class="block text-gray-600">Password</label>
              <input
                type="password"
                v-model="password"
                placeholder="Enter Password"
                minlength="4"
                class="
                  rounded-lg
                  mt-2
                  focus:border-blue-500 focus:bg-white focus:outline-none
                "
                required
              />
              <div class="validation_error on_passcode" id="errorPasscode">
                MUST ENTER A VALID PASSCODE
              </div>
            </div>

            <div class="text-right mt-2">
              <a
                @click="loginInView = false"
                class="
                  text-sm
                  font-semibold
                  text-gray-700
                  hover:text-blue-700
                  focus:text-blue-700
                  cursor-pointer
                "
                >Forgot Password?</a
              >
            </div>

            <button
              type="submit"
              id="login-button"
              class="
                w-full
                block
                bg-action-blue
                hover:opacity-75
                focus:bg-action-blue
                text-white
                font-semibold
                rounded-lg
                px-4
                py-3
                mt-6
              "
            >
              Log In
            </button>
          </form>

          <hr class="my-6 border-gray-300 w-full" />
          <div
            class="alert bg-red-300 rounded p-4 text-red-700"
            v-if="errorVisible"
          >
            <p v-html="error"></p>
          </div>
          <div
            style="width: 100%; text-align: center"
            class="spin-holder mt-4"
            v-if="loading"
          >
            <i class="spinner fa fa-spinner fa-spin"></i>
            <p>Loading</p>
          </div>
        </div>

        <div v-else>
          <forgottenpass @back="loginInView = true" />
        </div>
      </div>
    </section>
  </div>
</template>

  <script>
const forgottenpass = () => import("@/components/ForgotPassword.vue");

import { mapGetters } from "vuex";

export default {
  name: "login",
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    canlogin() {
      return this.username.trim().length > 2 && this.password.trim().length > 3;
    },
  },
  data() {
    return {
      isMobileView: false,
      loginInView: true,
      username: "",
      password: "",
      errorVisible: false,
      error: "",
      loading: false,
      networkMsg:
        "Could not connect to the server. <br/> Please contact our support team for further assistance.",
    };
  },
  created() {
    this.isMobile();
    this.checkCurrentLogin();
  },
  updated() {
    this.checkCurrentLogin();
  },
  methods: {
    isMobile() {
      if (screen.width <= 780) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },
    checkCurrentLogin() {
      if (this.currentUser) {
        //this.$router.replace(this.$route.query.redirect || '/mainx')
      }
    },
    login() {
      this.loading = true;
      this.errorVisible = false;

      var username = this.username.trim();
      var password = this.password.trim();

      this.$store
        .dispatch("login", { username, password })
        .then(() => this.loginSuccess())
        .catch((err) => this.loginFailure(err));
    },
    loginSuccess() {
      this.$store.dispatch("configure");
      this.loading = false;
      this.$router.push("/");
    },
    loginFailure(err) {
      this.loading = false;
      this.errorVisible = true;
      this.error = err === "network_error" ? this.networkMsg : "Login Failed!";
      this.$store.dispatch("logout");
    },
  },
  components: {
    forgottenpass,
  },
};
</script>

<style scoped>
/* validation messages */
.validation_error {
  display: none;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #ee6055;
  color: white;
  font-size: 130%;
  float: none;
  clear: both;
  margin-bottom: 12px;
}

.login-form-btn:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
